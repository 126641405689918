import styled from 'styled-components';

export const Container = styled.div`
  font-size: 14px;
  margin-top: 20px;
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 12px;
    font-size: 18px;
  }
`;

export const IndexContainer = styled.div`
  width: 20px;
  height: 20px;
  background: #DBA947;
  border-radius: 4px;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const Index = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #202121;
  transform: rotate(45deg);
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  font-size: 14px;
  grid-template-columns: min-content 1fr;
  grid-gap: 13px;
  margin-top: 20px;
  font-weight: 200;
  @media (min-width: 960px) {
    font-size: 16px;
  }
`;

import React from 'react';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import * as S from './styled';

const Header = () => {
  return (
    <S.Container>
      <Logo />
    </S.Container>
  )
}

export default Header;
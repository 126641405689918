import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 87px);
  @media (min-width: 960px) {
    height: 100vh;
    flex: 1 1;
    flex-direction: row-reverse;
  }
`;

export const Bg = styled.div`
  width: 100%;
  height: 220px;
  display: ${({mobileBg}) => mobileBg ? 'block' : 'none'};
  background-image: ${({mobileBg}) => `url(${mobileBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (min-width: 960px) {
    width: 50%;
    height: 100%;
    background-image: ${({bg}) => `url(${bg})`};
    background-size: cover;
    background-position: center;
    position: relative;
    display: block;
  }
`;

export const ShadowBg = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 1111;
  background: linear-gradient(360deg, #202121 0%, rgba(32, 33, 33, 0.817141) 10.82%, rgba(32, 33, 33, 0.47) 22.15%, rgba(32, 33, 33, 0.239956) 29.64%, rgba(32, 33, 33, 0) 40.75%), linear-gradient(180deg, #202121 0%, rgba(32, 33, 33, 0.493985) 16.22%, rgba(32, 33, 33, 0.239956) 31.81%, rgba(32, 33, 33, 0) 53.66%);
  @media (min-width: 960px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 200;
  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    font-size: 18px;
    width: 50%;
    flex: 1;
  }
`;

export const ContentDesktopContainer = styled.div`
  margin: auto;
`;

import React from 'react';

import finalDesc from '../../assets/images/finalDescBg.png';
import final from '../../assets/images/final.png';
import Button from '../../components/Button';
import HeaderDesktop from '../../components/HeaderDesktop';
import * as Common from '../common/styled';

const FinalPage = () => {
  return (
    <Common.Container>
      <Common.Bg bg={finalDesc} mobileBg={finalDesc}>
        <Common.ShadowBg />
      </Common.Bg>
      <Common.ContentWrapper>
        <HeaderDesktop />
        <Common.ContentDesktopContainer>
          Друзья, мы посвятили созданию умного бота @love_director <strong>ни один месяц упорного труда</strong>, потому что верим, что сайты знакомств далеко не полностью отвечают запросам современного человека. Нам, простым инженерам, так же как и вам, не всегда отвечали девушки на сайтах знакомств (чаще не отвечали!), поэтому мы твердо решили разработать такого бота, который устроил бы нас самих: бота, который подбирал бы действительно заинтересованных в знакомстве девушек.
          <p />Судите сами: через считанные недели после запуска у каждого из нас появилась пара, а один из членов команды - вообще скоро женится! 
          <p />Поэтому, дорогие друзья, <strong>не теряйте драгоценные минуты, ведь их можно провести за приятным общением с будущей любимой девушкой</strong>. Запускайте бота скорее и приятных вам знакомств!
          <p />Команда @love_director
          <Button title="ЗАПУСТИТЬ БОТ @love_director" />
        </Common.ContentDesktopContainer>
      </Common.ContentWrapper>
    </Common.Container>
  )
}

export default FinalPage;
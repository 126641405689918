import styled from 'styled-components';

export const TooltipBox = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px dashed rgba(224, 200, 107, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #DBA947;
  position: relative;
  font-weight: 200;
  @media (min-width: 960px) {
    font-size: 18px;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
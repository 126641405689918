import * as S from './styled';

const Button = ({style = {}, title = ''}) => {
  return (
    <S.Container style={style}>
      {title}
    </S.Container>
  )
}

export default Button;
import React from 'react';

import guideDescBg from '../../assets/images/guideDescBg.png';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import FooterDescktop from '../../components/FooterDesktop';
import * as S from './styled';
import * as Common from '../common/styled';
import Option from './Option';
import options from './options';

import { ReactComponent as TooltipLogo } from '../../assets/icons/tooltip.svg';
import HeaderDesktop from '../../components/HeaderDesktop';

const footerProps = {
  route: "/how-work",
  text: "Как работает Телеграм бот @love_director?",
};

const GuidePage = () => {
  return (
    <Common.Container>
      <Common.Bg bg={guideDescBg}>
        <Common.ShadowBg />
        <FooterDescktop {...footerProps} />
      </Common.Bg>
      <Common.ContentWrapper>
        <HeaderDesktop />
        <Common.ContentDesktopContainer>
          {
            options.map(option => <Option text={option.text} icon={option.icon} />)
          }
          <S.TooltipBox>
            <S.LogoContainer>
              <TooltipLogo />
            </S.LogoContainer>
            Телеграм бот @love_director находит <strong>девушек, которые уже заинтересованы в знакомстве</strong>, поэтому не упускайте момент и знакомьтесь сразу!
          </S.TooltipBox>
          <Button title="ЗАПУСТИТЬ БОТ @love_director" />
        </Common.ContentDesktopContainer>
      </Common.ContentWrapper>
      <Footer {...footerProps} />
    </Common.Container>
  )
}

export default GuidePage;
import React from 'react';

import howWorkDescBg from '../../assets/images/howWorkDescBg.png';
import howWorkBg from '../../assets/images/howwork-bg.png';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import * as Common from '../common/styled';

import options from './options';
import Option from './Option';
import FooterDescktop from '../../components/FooterDesktop';
import HeaderDesktop from '../../components/HeaderDesktop';

const footerProps = {
  route: "/why",
  text: "Почему мне стоит запустить Телеграм бот @love_director?",
};

const HowWorkPage = () => {
  return (
    <Common.Container>
      <Common.Bg bg={howWorkDescBg} mobileBg={howWorkDescBg}>
        <Common.ShadowBg />
        <FooterDescktop {...footerProps} />
      </Common.Bg>
      <Common.ContentWrapper>
        <HeaderDesktop />
        <Common.ContentDesktopContainer>
          {
            options.map((option, index) => <Option index={index + 1} content={option.content} />)
          }
          <Button  title="ЗАПУСТИТЬ БОТ @love_director" />
        </Common.ContentDesktopContainer>
      </Common.ContentWrapper>
      <Footer {...footerProps} />
    </Common.Container>
  )
}

export default HowWorkPage;
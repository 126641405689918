import styled from 'styled-components';

export const DesktopHideContent = styled.span`
  @media (min-width: 960px) {
    display: none;
  }
`;

export const InstallButton = styled.span`
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  max-width: min-content;
  margin-left: 8px;
`;
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

import * as S from './styled';

const FooterDescktop = ({route, text = ''}) => {
  const history = useHistory();
  const onPress = () => {
    history.push(route)
  }
  return (
    <S.Container onClick={onPress}>
      <S.Text>
      {text}
      </S.Text>
      <S.ArrowButton>
        <ArrowIcon />
      </S.ArrowButton>
    </S.Container>
  )
}

export default FooterDescktop;
import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-top: 40px;
  @media (max-width: 960px) {
    display: none;
  }
`;
import * as S from './styled';

const Option = ({content, index}) => {
  return (
    <S.Container>
      <S.IndexContainer>
        <S.Index>{index}</S.Index>
      </S.IndexContainer>
      {content}
    </S.Container>
  )
}

export default Option;
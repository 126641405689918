import * as S from './styled';

const Option = ({text, icon}) => {
  return (
    <S.Container>
      {icon}
      {text}
    </S.Container>
  )
}

export default Option;
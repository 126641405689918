import { InstallButton, DesktopHideContent } from './styled';

export default [
  {
    content: <span>Бот работает через приложение “Телеграм”. <p /> <DesktopHideContent>Не установлен Телеграм? <InstallButton>Установить</InstallButton></DesktopHideContent></span>
  },
  {
    content: <span>На базе указанного города, бот @love_director <strong>отбирает ТОП 5</strong> популярных сайтов знакомств среди девушек вашего города, используя открытые данные Сети.  </span>
  },
  {
    content: <span>Опираясь на указанную вами дату рождения <strong>и вашу фотографию</strong>, бот @love_director подбирает девушек, заинтересованных в знакомстве с мужчиной вашего возраста и типажа на отобранных сайтах из ТОП 5.</span>
  },
  {
    content: <span> <strong>1-3 раза в день</strong> бот @love_director будет отправлять вам фото заинтересованных в знакомстве девушек. Если девушка вам понравится - нажмите 👍🏻 и подождите взаимной симпатии от нее!</span>
  },
  {
    content: <span>Как только девушка ответит взаимной симпатией - бот @love_director тут же сообщит вам об этом и откроет чат для общения!</span>
  },
]
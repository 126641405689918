import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 38px;
  background: linear-gradient(244.55deg, hsl(29, 51%, 51%) -3.44%, hsl(40, 73%, 70%) 17.15%, #DBA947 48.02%, hsl(40, 67%, 57%) 67.74%, hsl(40, 54%, 54%) 100%);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2), inset 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 120px;
  color: #202121;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  cursor: pointer;
  :hover {
    background: linear-gradient(244.55deg, hsl(29, 51%, 39%) -3.44%, hsl(40, 73%, 58%) 17.15%, #DBA947 48.02%, hsl(40, 67%, 45%) 67.74%, hsl(40, 54%, 42%) 100%);
  }
  @media (min-width: 960px) {
    height: 64px;
    font-size: 24px;
  }
`;
import { ReactComponent as PressIcon } from '../../assets/icons/touch_app.svg';
import { ReactComponent as LightIcon } from '../../assets/icons/lightbulb_outline.svg';
import { ReactComponent as RoomIcon } from '../../assets/icons/room.svg';
import { ReactComponent as CakeIcon } from '../../assets/icons/cake.svg';
import { ReactComponent as AssignmentIcon } from '../../assets/icons/assignment_ind.svg';

export default [
  {
    text: <span><strong>Нажмите на кнопку ниже</strong>, чтобы запустить Телеграм бот @love_director абсолютно бесплатно.</span>,
    icon: <PressIcon />
  },
  {
    text: <span><strong>Следуйте подсказкам</strong> умного бота @love_director, чтобы создать максимально привлекательный профиль. Девушкам это понравится!</span>,
    icon: <LightIcon />
  },
  {
    text: <span><strong>Загрузите</strong> в бот @love_director <strong>качественную фотографию</strong>, чтобы девушки могли оценить вашу внешность по достоинству и ответить быстрее!</span>,
    icon: <AssignmentIcon />
  },
  {
    text: <span>Важно точно указать город, в котором вы живете, это поможет боту @love_director отобрать ТОП 5 сайтов знакомств, популярных среди девушек, именно <strong>в вашем городе!</strong></span>,
    icon: <RoomIcon />
  },
  {
    text: <span>Важно точно указать дату вашего рождения, т.к. девушкам иногда интереснее ваш знак зодиака, чем кубики пресса. </span>,
    icon: <CakeIcon />
  },
]
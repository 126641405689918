import Header from "./components/Header";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import DescriptionPage from "./pages/DescriptionPage";
import GuidePage from "./pages/GuidePage";
import HowWorkPage from "./pages/HowWorkPage";
import WhyPage from "./pages/WhyPage";
import FinalPage from "./pages/FinalPage";

import descriptionBg from './assets/images/description-bg.png';
import descriptionDescBg from './assets/images/descriptionDescBg.png';
import finalDesc from './assets/images/finalDescBg.png';
import final from './assets/images/final.png';
import guideDescBg from './assets/images/guideDescBg.png';
import howWorkDescBg from './assets/images/howWorkDescBg.png';
import howWorkBg from './assets/images/howwork-bg.png';
import whyDescBg from './assets/images/whyDescBg.png';
import whyBg from './assets/images/why.png';
import usePreloadImages from "./usePreloadImages";

const preload = [descriptionBg, descriptionDescBg, finalDesc, final, guideDescBg, howWorkDescBg, howWorkBg, whyDescBg, whyBg];
function App() {
  usePreloadImages(preload);
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={DescriptionPage} />
        <Route exact path="/guide" component={GuidePage} />
        <Route exact path="/how-work" component={HowWorkPage} />
        <Route exact path="/why" component={WhyPage} />
        <Route exact path="/final" component={FinalPage} />
      </Switch>
    </Router>
  )
}

export default App;

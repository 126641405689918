import styled from 'styled-components';

export const Container = styled.div`
  width: 540px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 16px;
  background: linear-gradient(261.43deg, #6BC2FD 0.57%, #309AE1 100%);
  box-shadow: 0px 0px 2px rgba(14, 67, 92, 0.3), 0px -2px 8px rgba(14, 67, 92, 0.3);
  position: absolute;
  bottom: 200px;
  left: -20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 960px) {
    display: none;
  }
`;

export const Text = styled.span`
  max-width: 450px;
  font-weight: bold;
  font-size: 20px;
  flex-grow: 1;
  margin-right: 10px;
`;

export const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
import React from 'react';

import descriptionBg from '../../assets/images/description-bg.png';
import descriptionDescBg from '../../assets/images/descriptionDescBg.png';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import FooterDescktop from '../../components/FooterDesktop';
import HeaderDesktop from '../../components/HeaderDesktop';
import * as Common from '../common/styled';
import { TextWrapper } from './styled';

const footerProps = {
  route: "/guide",
  text: "Как начать получать сообщения от заинтересованных в знакомстве девушек, прямо на мой Телеграм?",
};

const DescriptionPage = () => {
  return (
    <Common.Container>
      <Common.Bg mobileBg={descriptionDescBg} bg={descriptionDescBg}>
        <Common.ShadowBg />
        <FooterDescktop {...footerProps} />
      </Common.Bg>
      <Common.ContentWrapper>
        <HeaderDesktop />
        <Common.ContentDesktopContainer>
          <TextWrapper>
            Сообщения от <u><strong>заинтересованных девушек</strong></u> из разных сайтов знакомств теперь можно получать прямо на свой Телеграм!<p />
            <strong>Это абсолютно бесплатно</strong>: просто запустите Телеграм бот @love_director, укажите параметры поиска и начните получать сообщения от девушек, которые уже заинтересованы в знакомстве с вами!
          </TextWrapper>
          <Button title="ЗАПУСТИТЬ БОТ @love_director" />
        </Common.ContentDesktopContainer>
      </Common.ContentWrapper>
      <Footer {...footerProps} />
    </Common.Container>
  )
}

export default DescriptionPage;
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: auto;
  padding: 12px 24px;
  display: flex;
  background: linear-gradient(261.43deg, #6BC2FD 0.57%, #309AE1 100%);
  box-shadow: 0px 0px 2px rgba(14, 67, 92, 0.3), 0px -2px 8px rgba(14, 67, 92, 0.3);
  justify-content: space-between;
  align-items: center;
  justify-self: flex-end;
  @media (min-width: 960px) {
    display: none;
  }
`;

export const Text = styled.span`
  max-width: 230px;
  font-weight: bold;
  font-size: 14px;
  flex-grow: 1;
`;

export const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';

import whyDescBg from '../../assets/images/whyDescBg.png';
import whyBg from '../../assets/images/why.png';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import FooterDescktop from '../../components/FooterDesktop';
import HeaderDesktop from '../../components/HeaderDesktop';
import * as Common from '../common/styled';

import * as S from './styled';

const footerProps = {
  route: "/final",
  text: "Полезный совет от создателей Телеграм бота @love_director",
};

const WhyPage = () => {
  return (
    <Common.Container>
      <Common.Bg mobileBg={whyDescBg} bg={whyDescBg}>
        <Common.ShadowBg />
        <FooterDescktop {...footerProps} />
      </Common.Bg>
      <Common.ContentWrapper>
        <HeaderDesktop />
        <Common.ContentDesktopContainer>
          <span><S.Heart>&#9829;</S.Heart>  Девушки, отобранные ботом @love_director - уже заинтересованы в знакомстве с вами. <p /></span>
          <span><S.Heart>&#9829;</S.Heart>  Не нужно никого искать, бот @love_director сам найдет подходящих девушек для вас на ТОП 5 популярных в вашем городе сайтах знакомств. <p /></span>
          <span><S.Heart>&#9829;</S.Heart>  В базе бота @love_director девушек всегда больше, чем в отдельно взятом сайте знакомств. <p /></span>
          <span><S.Heart>&#9829;</S.Heart>  Бот @love_director <strong>отбирает девушек только на ТОП 5 популярных в вашем городе</strong> сайтах знакомств, автоматически отметая непроверенные профили.<p /></span>
          <span><S.Heart>&#9829;</S.Heart>  <strong>Услуга абсолютно бесплатна!</strong><p /></span>
          <Button title="ЗАПУСТИТЬ БОТ @love_director" />
        </Common.ContentDesktopContainer>
      </Common.ContentWrapper>
      <Footer {...footerProps} />
    </Common.Container>
  )
}

export default WhyPage;